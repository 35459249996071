<template>
  <div class="user-level-question">
    <div v-for="level in levels"
      :key="level"
      class="level"
      :class="{ current: isCurrentLevel(level) }"
      @click="setLevel(level)"
    >
      <img class="icon" :src="require(`@/assets/icons/smileys/${level}.svg`)" :alt="level">
    </div>
  </div>
</template>

<script>
import USER_LEVELS from '@/constants/USER_LEVELS'

export default {
  name: 'UserLevelQuestion',
  emits: ['set-level'],
  props: {
    level: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      levels: USER_LEVELS
    }
  },
  methods: {
    setLevel (level) {
      this.$emit('set-level', level)
    },
    isCurrentLevel (level) {
      return this.level === level
    }
  }
}
</script>

<style lang="scss" scoped>
.user-level-question {
  display: grid;
  gap: $space-xs;
  grid-template-columns: repeat(3, 1fr);
  .level {
    max-width: 116px;
    height: 110px;
    border-radius: $radius;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 1px rgba(181, 186, 190, 0.4);
    border: 1px solid $c-background;
    .icon {
      width: 100%;
      max-width: 90px;
      padding: 0 $space-s;
    }
    &.current {
      background-color: $c-background;
      box-shadow: none;
      border: 3px solid $c-secondary;
      cursor: default;
      .label {
        color: $c-secondary;
      }
    }
    &:hover {
      background-color: $c-background;
    }
  }
}

@media (min-width: 450px) {
  .user-level-question {
    grid-template-columns: repeat(3, 116px);
  }
}
</style>
