<template>
  <step-intro-layout :objectives="objectives">
    <template v-slot:content>
      <auto-evaluation-block :is-answered="hasUserLevel"
        :is-user-confident="isUserConfident"
        @go-to-activity="goToActivity"
        @go-to-lesson="goToNextStep"
      >
        <template v-slot:content>
          <user-level-question :level="userLevel" @set-level="setUserLevel"></user-level-question>
        </template>
      </auto-evaluation-block>
    </template>
  </step-intro-layout>
</template>

<script>
import StepIntroLayout from '@/components/training/StepIntroLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import UserLevelQuestion from '@/components/training/UserLevelQuestion'
import AutoEvaluationBlock from '@/components/training/AutoEvaluationBlock'
import useActivityUtils from '@/utils/useActivityUtils'

export default {
  name: 'Module1part2Intro',
  emits: ['next-step', 'step-finished'],
  components: { AutoEvaluationBlock, UserLevelQuestion, StepIntroLayout },
  setup (props, context) {
    const { goToNextStep, goToActivity } = useTrainingUtils()
    const { hasUserLevel, isUserConfident, setUserLevel, userLevel } = useActivityUtils()

    return { userLevel, hasUserLevel, isUserConfident, setUserLevel, goToNextStep, goToActivity }
  },
  computed: {
    objectives () {
      return [
        this.$t('module1.part2.intro.objectif1')
      ]
    }
  }
}
</script>
